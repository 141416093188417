/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';

import '../styles/main.scss';

import Header from './Header';
import Footer from './Footer';
import MobileHeader from './MobileHeader';

const Layout = ({ children }) => (
  <main id="main" className="bootstrap-wrapper">
    <h1 className="ir">
      {'Upendo Staffing'}
    </h1>
    <Header />
    <MobileHeader />
    <section id="content--wrapper">
      {children}
    </section>
    <Footer />
  </main>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
