/* eslint-disable */
const context = "http://schema.org";
const email = "info@upendostaffing.com";
const name = "David Ruzda";
const url = "https://upendostaffing.com";
const telephone = "561-518-8908";
const description = "Upendo Staffing is a South Florida talent agency staffing direct hire candidates in various industries providing employers candidates who love what they do.";

const address = {
  "@type": "PostalAddress",
  "addressLocality": "West Palm Beach",
  "addressRegion": "FL",
  "postalCode": "33409",
  "streetAddress": "1004 Green Pine Blvd."
};

const sameAs = [
  "https://twitter.com/StaffingUpendo",
  "https://www.linkedin.com/in/david-ruzga-71168a6a/",
  "https://www.instagram.com/upendo_staffing/"
];

const image = {
  "@type": "ImageObject",
  "url": "https://upendoStaffing.comimages/tile.png",
  "height": "1200",
  "width": "1200"
};

const logo = {
  "@type": "ImageObject",
  "url": "https://upendoStaffing.comimages/tile.png",
  "height": "1200",
  "width": "1200"
};

exports.data = [
  {
    "@context": context,
    "@type": "Corporation",
    "name": name,
    "url": url,
    "sameAs": sameAs,
    "image": image,
    "telephone": telephone,
    "email": email,
    "address": {
      ...address,
    },
    "logo": logo,
    "location": {
      "@type": "Place",
      "name": name,
      "telephone": telephone,
      "image": image,
      "logo": logo,
      "url": "https://upendoStaffing.com",
      "sameAs": sameAs,
      "address": {
        ...address
      }
    }
  },
  {
    "@context": context,
    "@type": "WebSite",
    "name": name,
    "description": description,
    "url": url,
    "image": "https://upendoStaffing.comimages/tile.png",
    "sameAs": sameAs,
    "copyrightHolder": {
      "@type": "Corporation",
      "name": name,
      "url": url,
      "sameAs": sameAs,
      "image": image,
      "telephone": telephone,
      "email": email,
      "address": {
        ...address
      },
      "logo": logo,
      "location": {
        "@type": "Place",
        "name": name,
        "telephone": telephone,
        "image": image,
        "logo": logo,
        "url": url,
        "sameAs": sameAs,
        "address": {
          ...address
        }
      }
    },
    "author": {
      "@type": "Corporation",
      "name": name,
      "url": url,
      "sameAs": sameAs,
      "image": image,
      "telephone": telephone,
      "email": email,
      "address": {
        ...address
      },
      "logo": logo,
      "location": {
        "@type": "Place",
        "name": name,
        "telephone": telephone,
        "image": image,
        "logo": logo,
        "url": url,
        "sameAs": sameAs,
        "address": {
          ...address
        }
      }
    },
    "creator": {
      "@type": "Organization"
    }
  },
  {
    "@context": context,
    "@type": "Place",
    "name": name,
    "telephone": telephone,
    "image": image,
    "logo": logo,
    "url": url,
    "sameAs": sameAs,
    "address": {
      ...address,
    }
  },
  {
    "@context": context,
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": "1",
        "item": {
          "@id": url,
          "name": "Home"
        }
      }
    ]
  }
];