import React, { useState } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';

import Logo from './Logo';

const MobileHeader = () => (
  <StaticQuery
    query={graphql`
      query MobileHeaderQuery {
        allContentfulCommonData {
          edges {
            node {
              navigation {
                items {
                  url
                  title
                  outlined
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      const [navOpen, navOpenSetter] = useState(false);

      return (
        <header id="mobile--header" className="container--md hidden--md hidden--lg">
          <button
            className={(navOpen === true) ? 'font--light border--none active' : 'font--light border--none'}
            type="button"
            onClick={() => navOpenSetter(!navOpen)}
          >
            <div className={(navOpen === true) ? 'nav--svg open' : 'nav--svg'}>
              <span />
              <strong className="ir">Menu</strong>
            </div>
          </button>
          <nav id="mobile--nav" className={(navOpen === true) ? 'active open' : ''}>
            <br />
            <br />
            { data.allContentfulCommonData.edges[0].node.navigation.items.map((item) => (
              <Link
                to={item.url}
                key={item.title}
              >
                <div className="padding--md font--offDark font--28">
                  <strong>
                    {item.title}
                  </strong>
                </div>
              </Link>
            ))}
          </nav>
          <section id="logo--wrapper" className="col-6 text--right">
            <Logo />
          </section>
        </header>
      );
    }}
  />
);

export default MobileHeader;
