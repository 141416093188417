import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import SocialIcons from './SocialIcons';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        allContentfulCommonData {
          edges {
            node {
              copyright
              socialLinks {
                data {
                  icon
                  url
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => (
      <section className="container--md">
        <footer id="footer" className="row padding--md">
          <section id="footer--social" className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="hidden--xs hidden--sm">
              <SocialIcons icons={data.allContentfulCommonData.edges[0].node.socialLinks.data} />
            </div>

            <div className="hidden--md hidden--lg text--center">
              <SocialIcons icons={data.allContentfulCommonData.edges[0].node.socialLinks.data} />
            </div>
          </section>
          <section id="footer--copyright" className="col-12 col-sm-12col-md-6 col-lg-6 font--footerText font--12 lh--lg">
            <div className="hidden--xs hidden--sm text--right">
              {`© ${new Date().getFullYear()} ${data.allContentfulCommonData.edges[0].node.copyright}`}
              <a href="/privacy-policy" className="font--upendoPrimary"> Privacy Policy</a>
              .
            </div>

            <div className="hidden--md hidden--lg text--center no--wrap">
              {`© ${new Date().getFullYear()} ${data.allContentfulCommonData.edges[0].node.copyright}`}
              <a href="/privacy-policy" className="font--upendoPrimary"> Privacy Policy</a>
              .
            </div>

            <a href="https://nerdswithcharisma.com" target="_blank" rel="noopener noreferrer" className="font--light float--right">
              {'Website by Nerds With Charisma'}
            </a>
          </section>
        </footer>
      </section>
    )}
  />
);

export default Footer;
