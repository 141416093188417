import React from 'react';
import { Link } from 'gatsby';
import { PropTypes } from 'prop-types';

const InnerHero = ({ tagline, image, showButtons }) => (
  <div
    id="innerHero"
    className="row"
    style={{
      background: `url(${image}) no-repeat center top fixed`,
      backgroundSize: 'contain',
      alignItems: 'center',
      display: 'flex',
      marginBottom: 25,
      paddingBottom: 50,
    }}
  >
    <div
      className="col-12 col-sm-12 col-md-12 col-lg-12 text--center font--26 font--light"
      style={{ width: '100%' }}
    >
      <br />
      <br />
      <br />
      <br />

      <h2 className="font--26">
        <strong>
          {tagline}
        </strong>
      </h2>


      { (showButtons === true) && (
        <div>
          <br />
          <Link to="/jobs" className="btn radius--lg bg--upendoPrimary font--light border--none font--14 no--wrap" style={{ width: 250, display: 'inline-block' }}>
            <strong>
              {'View Available Jobs '}
              <i className="fas fa-chevron-right" aria-hidden="true" />
            </strong>
          </Link>
          <span className="hidden--xs hidden--sm">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <br className="hidden--md hidden--lg" />
          <br className="hidden--md hidden--lg" />
          <Link to="/contact" className="btn radius--lg border border--upendoPrimary font--light font--14 shadow--sm no--wrap" style={{ width: 250, display: 'inline-block' }}>
            <strong>
              {'Contact Us '}
              <i className="fas fa-chevron-right" aria-hidden="true" />
            </strong>
          </Link>
        </div>
      )}
    </div>
  </div>
);

InnerHero.defaultProps = {
  showButtons: false,
};

InnerHero.propTypes = {
  tagline: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  showButtons: PropTypes.bool,
};

export default InnerHero;
