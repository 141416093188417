/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import schema from './schema-markup';

function SEO({ lang, meta, title }) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
    >
      {meta
        && meta.meta
        && meta.meta.map((item) => (
          <meta key={item.name} name={item.name} content={item.content} />
        ))}

      {schema
        && schema.data
        && schema.data.map((item) => (
          <script key="schema" type="application/ld+json">
            {JSON.stringify(item)}
          </script>
        ))}

      <script
        src="https://kit.fontawesome.com/ddaa6398b2.js"
        crossOrigin="anonymous"
      />
      {/* <script async src="https://kit.fontawesome.com/c817e1a82f.js" crossOrigin="anonymous" /> */}
      <meta name="theme-color" content="#19BCCE" />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
