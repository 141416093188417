import React from 'react';
import { Link } from 'gatsby';

import LogoImage from '../images/logo.svg';

const Logo = () => (
  <div>
    <Link to="/">
      <img
        src={LogoImage}
        alt="Logo, return to our homepage"
        style={{
          maxHeight: 95,
        }}
      />
    </Link>
  </div>
);

export default Logo;
