import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';

import Logo from './Logo';

const Header = () => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        allContentfulCommonData {
          edges {
            node {
              navigation {
                items {
                  url
                  title
                  outlined
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => (
      <header id="header" className="container--md row hidden--xs hidden--sm">
        <section id="logo--wrapper" className="col-3">
          <br />
          <Logo />
          <br />
        </section>
        <section id="nav--wrapper" className="col-9 text--right">
          <br />
          <br />
          { data.allContentfulCommonData.edges[0].node.navigation.items.map((item) => (
            <Link
              to={item.url}
              key={item.title}
              className={(item.outlined === true) ? 'border radius--lg padding--sm border--light' : ''}
            >
              &nbsp;&nbsp;
              <span className="padding--md font--light font--16">
                {item.title}
              </span>
              &nbsp;&nbsp;
            </Link>
          ))}
          <br />
        </section>
      </header>
    )}
  />
);

export default Header;
