import React from 'react';
import { PropTypes } from 'prop-types';

const SocialIcons = ({ icons }) => (
  <span className="font--18" id="social--icons">
    { icons.map(((icon) => (
      <a
        href={icon.url}
        target="_blank"
        key={icon.url}
        rel="noopener noreferrer"
        className="font--offDark padding--sm"
      >
        &nbsp;
        <i className={icon.icon} />
        <span className="ir">
          {icon.url}
        </span>
        &nbsp;
      </a>
    )))}
  </span>
);

SocialIcons.propTypes = {
  icons: PropTypes.array.isRequired,
};

export default SocialIcons;
